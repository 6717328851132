import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  textsArray: string[];
}

const TextLoop: React.FC<Props> = ({ textsArray }) => {
  const [index, setIndex] = useState(0);
  const components = textsArray.map((text: string) => {
    return <span className="font-medium">{text}</span>;
  });
  useEffect(() => {
    setTimeout(() => {
      const texts = React.Children.toArray(components);
      let next = index + 1;
      if (next === texts.length) {
        next = 0;
      }
      setIndex(next);
    }, 2500);
  }, [index, components]);

  return (
    <AnimatePresence>
      <motion.span
        key={index}
        initial={{
          opacity: 0,
        }}
        transition={{
          y: { type: "spring", stiffness: 300, damping: 200 },
          opacity: { duration: 0.3 },
        }}
        animate={{
          zIndex: 0,
          position: "relative",
          y: 0,
          opacity: 1,
        }}
        exit={{
          display: "none",
        }}
      >
        {components && React.Children.toArray(components)[index]}
      </motion.span>
    </AnimatePresence>
  );
};

export default TextLoop;
